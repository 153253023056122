import * as React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo/seo";
import PageContainer from "../components/ui-library/page-container/page-container";
import Container from "../components/ui-library/container/container";
import { LogoBunny } from "../components/site/header/header";

const SuggestQuestionAndAnswerPage: React.FC = () => {
  return (
    <Layout headerUrl="/" logoBunny={LogoBunny.FaceMask}>
      <SEO
        title="Suggest a good question and answer"
        description="Suggest a question and your preferred answer"
        meta={[{ name: "robots", content: "noindex" }]}
      />

      <PageContainer>
        <Container
          fullWidthOnMobile={true}
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSeO1HwDBvSF7HXEo9_WKmfM0UvAVuyiqKOySIoPgvxO6DAXkQ/viewform?embedded=true"
            width="950"
            height="1300"
            frameBorder="0"
            css={{ maxWidth: "100%" }}
          >
            Loading…
          </iframe>
        </Container>
      </PageContainer>
    </Layout>
  );
};

export default SuggestQuestionAndAnswerPage;
